import React from "react"
import Layout from "../components/layout"
import InformationBlock from "../components/informationBlock"
import PriceList from "../components/priceList"

const pricing = () => {
  return (
    <Layout fullWidth>
      <InformationBlock />
      <PriceList />
    </Layout>
  )
}

export default pricing
